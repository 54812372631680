/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state/state";
import { Container, Text } from "@atoms";

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  return (
    <>
      <div className="flex flex-col items-center text-center justify-center flex-grow">
        <Container>
          <Text variant="h3">404 Page Not Found</Text>
          <Text variant="lg">Looks like there&apos;s nothing here</Text>
        </Container>
      </div>
    </>
  );
};

export default NotFoundPage;
